import builderPathInit from '../../util/builder-path-init';
import { builder } from '@builder.io/react/lite';
import getConfig from 'next/config';
import { fetchFilteredArticles } from '@glowforge/builder-components/lib/util/fetchFilteredArticles';
import { removeDataBlocks } from '../../util/remove-data-blocks';
const model = 'blog-page';
const pathPrefix = '/blog';

const {
  publicRuntimeConfig: { GF_DOTCOM_ENV },
} = getConfig();

const builderComponentQuery =
  GF_DOTCOM_ENV === 'production'
    ? {
        query: {
          data: {
            environment: GF_DOTCOM_ENV ?? 'production',
          },
        },
      }
    : {};

const { BuilderPage } = builderPathInit({ model: model });

export default BuilderPage;
export async function getServerSideProps(context) {
  const {
    params: { id },
    query: { q },
    res
  } = context;
  const urlPath = id ? `${pathPrefix}/${id}` : pathPrefix;
  const content = await builder
    .get(model, {
      userAttributes: {
        urlPath,
        ...builderComponentQuery,
      },
      options: { enrich: true, includeRefs: true },
    })
    .promise();

  if (!content) {
    return {
      notFound: true,
    };
  }
  removeDataBlocks(content.data.blocks);

  const ALLOWED_CACHE_HEADERS = {
    sMaxage: 's-maxage',
    staleWhileRevalidate: 'stale-while-revalidate',
    noCache: 'no-cache',
  };

  const { title = null, cacheControl } = content.data;
  if (cacheControl && Object.keys(cacheControl).length > 0) {
    const cacheControlHeader = [];
    const noCacheHeader = [];
    Object.entries(cacheControl).forEach(([key, value]) => {
      if (typeof value === 'number' && key in ALLOWED_CACHE_HEADERS) {
        cacheControlHeader.push(
          `${ALLOWED_CACHE_HEADERS[key]}=${value > 3600 ? 3600 : value}`
        );
      }
      if (key === ALLOWED_CACHE_HEADERS.noCache && value === true) {
        noCacheHeader.push(
          `${ALLOWED_CACHE_HEADERS[key]}=${value}`
        );
        noCacheHeader.push('private');
        noCacheHeader.push('max-age: 0');
      }
    });
    res.setHeader(
      'Cache-Control',
      noCacheHeader.length > 0 ? noCacheHeader : cacheControlHeader
    );
  }

  const latestArticles = await builder.getAll('blog-article', {
    options: {
      noTargeting: true,
      enrich: true,
      sort: {
        'data.date': -1
    }   
    },
    fields: 'data.title,data.image,data.summary,data.slug,data.date,data.author.value.data.name,data.author.value.data.slug,data.category.value.data.name,data.category.value.data.slug',
    limit: 10,
    
  });
  content['data'] = {
    ...content.data,
    latestArticles: latestArticles
  };

  let isBlogSearchFound = false;
  if (q) {
    const modelId = content?.id;
    const serverFiltered = await fetchFilteredArticles('search', modelId, 0, 8, q, true);
    if (serverFiltered) {
      isBlogSearchFound = serverFiltered.articlesToSet.length > 0 ? true : false;
      content['data'] = {
        ...content.data,
        serverFiltered: serverFiltered,
      };
    }
  }
  return {
    props: {
      content,
      title,
      isBlogSearchFound
    },
  };
}
